<template>
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&icon_names=open_in_new" />

  <div class="container">
      <router-view></router-view>
  </div>
</template>

<script>

export default {

}

</script> 


 
<style scoped>

</style>